import React from 'react';
import { useTranslation } from 'react-i18next';

import withI18n from '../utils/hocs';
import Layout from '../components/Layout';

import styles from './404.module.scss';
import LocalizedLink from '../components/LocalizedLink';
import SEO from '../components/SEO';

import ogImage from '../images/fb-image.png';
import ogImageEn from '../images/fb-image_en.png';

const Error404Page = (): React.ReactElement => {
  const { t, i18n } = useTranslation();
  return (
    <Layout>
      <SEO
        title={t('SEO.homepage.title', 'Mosca Digital - O digital criativo')}
        description={t('SEO.homepage.description', 'O digital não tem de ser complicado')}
        url={'https://moscadigital.pt' + (i18n.language === 'en' ? '/en' : '')}
        imageUrl={i18n.language === 'en' ? ogImageEn : ogImage}
      />
      <div className={styles.main}>
        <div className={styles.particleCanvas} />
        <div className={styles.content}>
          <p className={styles.pageNotFoundMessage}>{t('404.message', 'Página não encontrada')}</p>
          <LocalizedLink to="/" className={styles.goBackButton} target="_blank" rel="noreferrer">
            {t('404.go_back_home_btn_text', 'Voltar ao início')}
          </LocalizedLink>
        </div>
      </div>
    </Layout>
  );
};

export default withI18n(Error404Page);
